import React from 'react'

import { Section } from './style'

const TourVirtual = () => {
  return (
    <Section className=''>
      <iframe
        width='100%' height='100%'
        allow='xr-spatial-tracking; gyroscope; accelerometer'
        allowFullScreen
        src='https://imdesigns.viewin360.co/InterVIPLounge'
      />
    </Section>
  )
}

export default TourVirtual
